import React from "react";
import { FormControlLabel, Checkbox as MuiCheckbox } from "@mui/material";

const Checkbox = ({ name = "", labelPlacement = "end", className = "", label = "", checked, onChange = () => null, ...props }) => {
  return (
    <FormControlLabel
      slotProps={{
        typography: {
          className: `${className} !text-sm !font-medium`,
        },
      }}
      label={label}
      labelPlacement={labelPlacement}
      control={<MuiCheckbox name={name} checked={checked} onChange={onChange} {...props} />}
    />
  );
};

export default Checkbox;
