export const formatBrandName = (brandName) => {
    if (!brandName) {
        return 'Wayfair';
    }
    return brandName?.includes('fourhands') ? 'Four Hands' : brandName;
};

export const getFormattedImageLink = (brandName, mainImage, additionalImages) => {

    if (brandName?.includes('fourhands')) {
        let imagesArr = additionalImages?.split(',');
        let imageLink = findNextImage(imagesArr);        
        return imageLink;
    }

    console.log('mainImage', mainImage);
    
    return mainImage;
}

// if current image string is empty, go to next image
const findNextImage = (images) => {
    if (images?.length > 1) {
        images = images.slice(1);
    }
    let nextImage = images.find((image) => image !== '');
    return nextImage;
}